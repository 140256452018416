import { cn } from 'utils/classnames'
import { Dropdown } from './Dropdown'
import { BRIDGE_SUPPORTED_NETWORKS } from 'utils/across/transaction'
import useAcross from 'hooks/across/useAcross'
import DropdownContent from './DropdownContent'
import { zksync } from 'viem/zksync'
import Button from '@components/shared/Button'
import { useMemo, useState } from 'react'
import { Address, Chain } from 'viem'
import useWallet from 'rfx/lib/wallets/useWallet'
import BuyInputSection from 'components/BuyInputSection/BuyInputSection'
import { formatTokenAmount, parseValue } from 'rfx/lib/numbers'
import { formatUsd } from 'rfx/lib/numbers'
import { formatAmountFree } from 'rfx/lib/numbers'
import { limitDecimals } from 'rfx/lib/numbers'
import { MAX_METAMASK_MOBILE_DECIMALS } from 'config/ui'
import TokenSelector from 'components/TokenSelector/TokenSelector'
import AcrossLogo from '../../../../../public/logos/across.svg'
import Info from '../../../../../public/icons/info.svg'

import {
  TokenData,
  TokensData,
  convertToUsd,
  getTokenData,
  useTokenBalances,
} from 'domain/synthetics/tokens'
import useTokenPrice from 'hooks/rfx/usePyth/useTokenPrice'
import { getToken, getV2Tokens } from 'config/tokens'
import { BigNumber } from 'ethers'
import { DUST_BNB } from 'rfx/lib/legacy'
import useIsMetamaskMobile from 'rfx/lib/wallets/useIsMetamaskMobile'
import { useAvailableTokenOptions } from 'domain/synthetics/trade'
import { SYNTHETICS_MARKET_DEPOSIT_TOKEN_KEY } from 'config/localStorage'
import { useLocalStorageSerializeKey } from 'rfx/lib/localStorage'

import Image from 'next/image'
import { showCallContractToast } from 'rfx/lib/contracts'
import React from 'react'
import { InfoTokens } from 'domain/tokens'
import RenderTokenIcon from '@components/shared/RenderTokenIcon'

const BridgeForm = () => {
  const { account } = useWallet()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {
    selectedChain,
    setSourceOfChain,
    shouldSwitchNetwork,
    switchNetwork,
    executeTransaction,
  } = useAcross()

  const [firstTokenAddress, setFirstTokenAddress] = useLocalStorageSerializeKey<
    string | undefined
  >(
    [selectedChain?.id, SYNTHETICS_MARKET_DEPOSIT_TOKEN_KEY, 'first'],
    getV2Tokens(selectedChain?.id)[0]?.address,
  )
  const chainBalances = useTokenBalances(selectedChain?.id)

  const v2Tokens = getV2Tokens(selectedChain?.id)
  const isMetamaskMobile = useIsMetamaskMobile()

  const { infoTokens: _iT } = useAvailableTokenOptions()

  const chainTokenData: TokensData = useMemo(() => {
    return Object.keys(chainBalances?.balancesData || {}).reduce(
      (acc, address) => {
        const bn = chainBalances?.balancesData?.[address]

        const token = v2Tokens?.find((t) => t.address === address)!

        const tD: TokenData = {
          balance: bn,
          ...token,
          prices: { minPrice: BigNumber.from(0), maxPrice: BigNumber.from(0) },
        }

        return {
          ...acc,
          [address]: tD,
        }
      },
      {},
    )
  }, [chainBalances?.balancesData, v2Tokens])

  const firstToken = getTokenData(chainTokenData, firstTokenAddress)

  const firstTokenPrice = useTokenPrice(
    firstToken?.pythId || '',
    firstToken?.address || '',
    'maxPrice',
  )

  const [firstTokenInputValue, setFirstTokenInputValue] = useState<string>('')
  const firstTokenAmount = parseValue(
    firstTokenInputValue,
    firstToken?.decimals || 0,
  )
  const firstTokenUsd = convertToUsd(
    firstTokenAmount,
    firstToken?.decimals,
    firstTokenPrice,
  )

  const chainOptions = Object.keys(BRIDGE_SUPPORTED_NETWORKS).map((network) => {
    const chain = (BRIDGE_SUPPORTED_NETWORKS as any)[network] as Chain
    return {
      label: <DropdownContent chain={chain} usdcBalance={100} />,
      value: chain.id,
    }
  })

  React.useEffect(() => {
    const _ft = getV2Tokens(selectedChain?.id)?.[0]

    _ft && setFirstTokenAddress(_ft.address)
  }, [selectedChain?.id, setFirstTokenAddress])

  const submitState = useMemo(() => {
    if (!account) {
      return {
        text: `Connect Wallet`,
        isDisabled: true,
        onSubmit: () => {},
      }
    }
    if (shouldSwitchNetwork) {
      return {
        text: 'Change network to confirm',
        isDisabled: false,
        onSubmit: () => {
          switchNetwork()
        },
      }
    }

    return {
      text: 'Bridge',
      isDisabled: false,
      onSubmit: () => {
        if (!firstToken) {
          return
        }
        setIsSubmitting(true)
        const promise = executeTransaction({
          /**
           * here we should pass the right amount
           */
          amount: BigInt(
            Number(firstTokenInputValue) * Math.pow(10, firstToken?.decimals),
          ),
          inputTokenAddress: firstTokenAddress as Address,
          inputToken: firstToken,
        })

        promise
          ?.then((res: any) => {
            if (res && res[13].transactionHash) {
              showCallContractToast({
                chainId: selectedChain?.id,
                hash: res[13].transactionHash,
                sentMsg: 'Transaction Sent!',
              })
            }
            setIsSubmitting(false)
          })
          .finally(() => {
            setIsSubmitting(false)
          })
      },
    }
  }, [
    account,
    shouldSwitchNetwork,
    switchNetwork,
    firstToken,
    executeTransaction,
    firstTokenInputValue,
    firstTokenAddress,
    selectedChain?.id,
  ])

  const sourceOfChain: { label: React.ReactNode; value: number } = {
    label: (
      <DropdownContent
        chain={selectedChain}
        hideSymbol
        nameClassName={cn('font-medium text-sm text-th-fgd-1')}
      />
    ),
    value: selectedChain.id,
  }

  const tokenOptions = useMemo(() => {
    const _tokens = Object.values(chainTokenData || [])

    return _tokens.reduce((acc, token) => {
      try {
        getToken(selectedChain.id, token.address, true)

        return [...acc, token]
      } catch (e) {
        return acc
      }
    }, [] as TokenData[])
  }, [chainTokenData, selectedChain.id])

  const infoTokens = useMemo(() => {
    return Object.keys(_iT).reduce((acc, address) => {
      const token = _iT[address]
      const matchingToken = tokenOptions.find((t) => t.address === address)
      if (matchingToken) {
        return {
          ...acc,
          [address]: {
            ...token,
            balance: matchingToken.balance,
          },
        }
      }
      return acc
    }, {} as InfoTokens)
  }, [_iT, tokenOptions])

  return (
    <>
      <div className={cn('flex flex-col')}>
        From:
        <div className="my-2 inline-flex flex-col items-start justify-center gap-2.5 rounded-sm border border-[#909095] px-2 py-2">
          <div className="flex gap-2 text-[12px] font-normal text-[#909095]">
            <div>
              <Info />
            </div>
            <div>
              Please use the zkSync native bridge to bridge wstETH or deUSD from
              Ethereum.
            </div>
          </div>
        </div>
        <div
          className={cn(
            'source-chain flex flex-row items-center justify-between pt-4 text-[#909095]',
          )}
        >
          <div>Chain</div>
          <div
            className={cn('flex flex-row items-center gap-2 text-[#909095]')}
          >
            Powered by Across
            <AcrossLogo />
          </div>
        </div>
        <div className={cn('w-full pt-2')}>
          <Dropdown
            options={chainOptions}
            selectedOption={sourceOfChain}
            setSelectedOption={(x) => setSourceOfChain(x.value)}
          />
        </div>
        <BuyInputSection
          topLeftLabel={'Pay'}
          topLeftValue={formatUsd(firstTokenUsd)}
          topRightLabel={`Balance`}
          topRightValue={formatTokenAmount(
            firstToken?.balance,
            firstToken?.decimals,
            '',
            {
              useCommas: true,
            },
          )}
          showMaxButton={
            firstToken?.balance?.gt(0) &&
            !firstTokenAmount?.eq(firstToken.balance)
          }
          inputValue={firstTokenInputValue}
          onInputValueChange={(e) => {
            if (firstToken) {
              setFirstTokenInputValue(e.target.value)
            }
          }}
          onClickMax={() => {
            if (firstToken?.balance) {
              const maxAvailableAmount = firstToken.isNative
                ? firstToken.balance.sub(BigNumber.from(DUST_BNB).mul(2))
                : firstToken.balance

              const formattedMaxAvailableAmount = formatAmountFree(
                maxAvailableAmount,
                firstToken.decimals,
              )
              const finalAmount = isMetamaskMobile
                ? limitDecimals(
                    formattedMaxAvailableAmount,
                    MAX_METAMASK_MOBILE_DECIMALS,
                  )
                : formattedMaxAvailableAmount

              setFirstTokenInputValue(finalAmount)
            }
          }}
        >
          {firstTokenAddress ? (
            <TokenSelector
              label={`Pay`}
              chainId={selectedChain.id}
              tokenAddress={firstTokenAddress}
              onSelectToken={(token) => setFirstTokenAddress(token.address)}
              tokens={tokenOptions}
              infoTokens={infoTokens}
              showSymbolImage={true}
              showTokenImgInDropdown={true}
            />
          ) : (
            <div className="selected-token">
              <RenderTokenIcon symbol={firstToken?.symbol} />
            </div>
          )}
        </BuyInputSection>
      </div>
      <div className="my-6 flex w-full justify-center">
        <Image
          alt="swap"
          width={32}
          height={32}
          src="/images/rfx-swap.gif"
          className="select-none"
        />
      </div>
      To:
      <div
        className={cn(
          'source-chain flex flex-row items-center justify-between pt-4 text-[#909095]',
        )}
      >
        <div>Chain</div>
        <div className={cn('flex flex-row items-center gap-2 text-[#909095]')}>
          Powered by Across
          <AcrossLogo />
        </div>
      </div>
      <div className={cn('w-full pt-2')}>
        <Dropdown
          options={[
            {
              label: <DropdownContent chain={zksync} usdcBalance={100} />,
              value: zksync.id,
            },
          ]}
          selectedOption={{
            label: (
              <DropdownContent
                chain={zksync}
                hideSymbol
                nameClassName={cn('font-medium text-sm text-th-fgd-1')}
              />
            ),
            value: zksync.id,
          }}
        />
      </div>
      <div className="Exchange-swap-button-container mt-3">
        <Button
          className={`w-full uppercase ${
            submitState?.text === 'Connect Wallet' && 'connect-wallet'
          }`}
          onClick={submitState?.onSubmit}
          size="large"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : submitState?.text}
        </Button>
      </div>
    </>
  )
}

export default BridgeForm
