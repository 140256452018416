import { Address } from 'viem'

import { spokePoolContractAbi } from './across'
import { BigNumber, Contract, ethers, Signer } from 'ethers'

import { base, mainnet, polygon, arbitrum, optimism, linea } from 'viem/chains'
import { ZKSYNC } from 'config/chains'
import { Token } from 'config/tokens'
import TokenAbi from 'abis/Token.json'
import { IS_VERBOSE } from 'config/development'
export const BRIDGE_SUPPORTED_NETWORKS = {
  base: base,
  ethereum: mainnet,
  polygon: polygon,
  arbitrum: arbitrum,
  optimism: optimism,
  linea: linea,
}

export async function getGasLimit(
  contract: Contract,
  method: any,
  params: any[] = [],
  value?: BigNumber | number,
) {
  const defaultValue = BigNumber.from(0)
  if (!value) {
    value = defaultValue
  }
  let gasLimit = BigNumber.from(0)
  try {
    gasLimit = await contract.estimateGas[method](...params, { value })
  } catch (e) {
    // eslint-disable-next-line no-console
    IS_VERBOSE && console.log({ e })
  }

  return gasLimit.isZero() ? 7920027 : gasLimit
}

export function getChainIdFromName(
  name: keyof typeof BRIDGE_SUPPORTED_NETWORKS,
) {
  const id = BRIDGE_SUPPORTED_NETWORKS[name]?.id
  if (!id) {
    throw new Error(`Chain ${name} not supported`)
  }

  return id
}

// Bridging powered by Across
// https://docs.across.to/integration-guides/across-bridge-integration
export const transaction = async (opts: {
  signer: Signer | undefined
  inputToken: Token
  amount: number
  chainId: string
  currentUserAddress: string | undefined
}) => {
  const { signer: _signer } = opts
  if (!_signer) {
    return
  }

  const inputToken = opts.inputToken.acrossAddress || opts.inputToken.address
  const outputToken =
    opts.inputToken.symbol === 'USDC'
      ? '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4'
      : '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91'
  const parsedAmount = BigInt(opts.amount)
  const originChainId = opts?.chainId
  const destinationChainId = ZKSYNC

  if (!destinationChainId) {
    return
  }

  const url = 'https://app.across.to/api/suggested-fees'
  const params = new URLSearchParams({
    inputToken,
    outputToken,
    originChainId: originChainId.toString(),
    destinationChainId: destinationChainId.toString(),
    amount: parsedAmount.toString(),
  })

  const endpoint = `${url}?${params.toString()}`
  const acrossRes = await fetch(endpoint)
  const across = (await acrossRes.json()) as {
    totalRelayFee: { total: string }
    timestamp: string
    spokePoolAddress: Address
  }

  // Check allowance and request approval if needed
  const tokenContract = new ethers.Contract(
    opts.inputToken.acrossAddress || opts.inputToken.address,
    TokenAbi.abi,
    _signer,
  )
  const allowance = await tokenContract.allowance(
    opts.currentUserAddress,
    across.spokePoolAddress,
  )

  if (allowance.lt(parsedAmount)) {
    try {
      const approveTx = await tokenContract.approve(
        across.spokePoolAddress,
        ethers.constants.MaxUint256,
      )
      await approveTx.wait()
    } catch (error) {
      // eslint-disable-next-line no-console
      IS_VERBOSE && console.error('Error during approval:', error)
      throw new Error('Failed to approve token transfer')
    }
  }

  const contract = new ethers.Contract(
    across.spokePoolAddress,
    spokePoolContractAbi,
    _signer,
  )

  const depositor = opts?.currentUserAddress
  const receiver = opts?.currentUserAddress

  const iface = new ethers.utils.Interface(spokePoolContractAbi)
  const values = [
    depositor, // depositor
    receiver, // recipient
    inputToken, // inputToken
    outputToken, // outputToken
    parsedAmount, // inputAmount
    parsedAmount - BigInt(across.totalRelayFee.total), // outputAmount
    destinationChainId, // destinationChainId
    '0x0000000000000000000000000000000000000000', // exclusiveRelayer
    Number(across.timestamp), // quoteTimestamp
    Math.round(Date.now() / 1000) + 600, // fillDeadline (10 minutes)
    0, // exclusivityDeadline
    '0x', // message
  ]

  const gl = await getGasLimit(contract, 'depositV3', values)

  let calldata = iface.encodeFunctionData('depositV3', values)

  calldata = `${calldata}1dc0de0014`

  // inputToken, outputToken, inputAmount, outputAmount, destinationChainId, depositId, quoteTimestamp, fillDeadline, exclusivityDeadline, depositor, recipient, exclusiveRelayer, message
  const filters = contract.filters.V3FundsDeposited(
    null,
    null,
    null,
    null,
    destinationChainId,
    null,
    null,
    null,
    null,
    depositor,
    null,
    null,
    null,
  )

  const eventPromise = new Promise((resolve, reject) => {
    const cb = (...args: any[]) => {
      resolve(args)
    }
    contract.on(filters, cb)

    setTimeout(() => {
      contract.off(filters, cb)
      reject('Timeout')
    }, 1000 * 180)
  })

  const call: ethers.utils.Deferrable<ethers.providers.TransactionRequest> = {
    to: across.spokePoolAddress,
    value: ['USDC', 'wETH'].includes(opts.inputToken.symbol)
      ? BigNumber.from(0)
      : parsedAmount,
    data: calldata,
    gasLimit: gl,
  }

  try {
    await _signer.sendTransaction(call)
    return eventPromise
  } catch (e) {
    // eslint-disable-next-line no-console
    IS_VERBOSE && console.error('No event found', e)
  }

  return
}
