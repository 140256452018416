import { Listbox } from '@headlessui/react'
import { FaChevronDown } from 'react-icons/fa'
import { cn } from 'utils/classnames'

type Option = { label: React.ReactNode; value: any }

export const Dropdown = ({
  options,
  selectedOption,
  setSelectedOption,
  className,
}: {
  className?: string
  options: Option[]
  selectedOption: Option
  setSelectedOption?: (value: Option) => void
}) => {
  return (
    <Listbox
      value={selectedOption}
      onChange={(option) => setSelectedOption && setSelectedOption(option)}
    >
      <div className={cn('relative', className)}>
        <Listbox.Button className="w-full">
          <div className="flex h-10 items-center justify-between rounded border border-th-input-border bg-th-bkg-2 px-2 text-sm font-medium text-th-fgd-1">
            <span>{selectedOption.label}</span>
            <span>
              <FaChevronDown size={16} color="#909096" fontWeight={400} />
            </span>
          </div>
        </Listbox.Button>
        <Listbox.Options className="absolute z-50 mt-2 max-h-48 w-full overflow-y-scroll bg-th-bkg-5 text-sm font-medium text-th-fgd-1">
          {options.map((option, i) => (
            <Listbox.Option
              className="flex h-12 cursor-pointer items-center border-b border-th-input-border px-4 last-of-type:!border-none hover:bg-th-bkg-2"
              key={option?.value + i}
              value={option}
            >
              {option.label}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  )
}
