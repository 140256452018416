import React from 'react'
import { transaction } from 'utils/across/transaction'
import {
  arbitrum,
  base,
  Chain,
  linea,
  mainnet,
  optimism,
  polygon,
  zksync,
  zksyncSepoliaTestnet,
} from 'viem/chains'
import { useAccount, useChainId, useSwitchChain } from 'wagmi'
import { useEthersSigner } from '../contracts/useEthersSigner'
import { Address } from 'viem'
import { DEFAULT_CHAIN_ID } from 'config/chains'
import { Token } from 'config/tokens'

export const BRIDGE_SUPPORTED_NETWORKS: Record<number, Chain> = {
  [arbitrum.id]: arbitrum,
  [base.id]: base,
  [mainnet.id]: mainnet,
  [linea.id]: linea,
  [optimism.id]: optimism,
  [polygon.id]: polygon,
  [zksync.id]: zksync,
  [zksyncSepoliaTestnet.id]: zksync,
}

function useAcross() {
  const { address } = useAccount()
  const chainId = useChainId()

  const [_sourceOfChain, setSourceOfChain] = React.useState<number>(chainId)

  const selectedChain = BRIDGE_SUPPORTED_NETWORKS[_sourceOfChain]

  const { switchChain: _sN } = useSwitchChain()

  const shouldSwitchNetwork = chainId !== selectedChain.id

  const switchNetwork = React.useCallback(() => {
    _sN?.({ chainId: selectedChain.id })
  }, [_sN, selectedChain.id])
  const signer = useEthersSigner({
    chainId: selectedChain.id || DEFAULT_CHAIN_ID,
  })

  const executeTransaction = React.useCallback(
    ({
      amount,
      inputTokenAddress,
      inputToken,
    }: {
      amount: bigint
      inputTokenAddress: Address
      inputToken: Token
    }) => {
      if (!signer || !address) {
        return
      }

      const opts = {
        inputTokenAddress: inputTokenAddress,
        inputToken,
        currentUserAddress: address,
        amount: Number(amount),
        chainId: chainId.toString(),
        signer: signer,
      }
      return transaction(opts)
    },
    [address, chainId, selectedChain.id, signer],
  )

  return {
    shouldSwitchNetwork,
    chainId,
    selectedChain,
    switchNetwork,
    setSourceOfChain,
    executeTransaction,
  }
}

export default useAcross
