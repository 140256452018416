import ButtonGroup from '@components/forms/ButtonGroup'
import Button from '@components/shared/Button'
import RenderTokenIcon from '@components/shared/RenderTokenIcon'
import TabUnderline from '@components/shared/TabUnderline'
import Tooltip from '@components/shared/Tooltip'
import BuyInputSection from 'components/BuyInputSection/BuyInputSection'
import Checkbox from 'components/Checkbox/Checkbox'
import { PoolSelector } from 'components/MarketSelector/PoolSelector'
import TokenSelector from 'components/TokenSelector/TokenSelector'
import { HIGH_PRICE_IMPACT_BPS } from 'config/factors'
import {
  SYNTHETICS_MARKET_DEPOSIT_TOKEN_KEY,
  getSyntheticsDepositIndexTokenKey,
} from 'config/localStorage'
import { NATIVE_TOKEN_ADDRESS, convertTokenAddress } from 'config/tokens'
import { MAX_METAMASK_MOBILE_DECIMALS } from 'config/ui'
import { useHasOutdatedUi } from 'domain/legacy'
import {
  FeeItem,
  estimateExecuteDepositGasLimit,
  estimateExecuteWithdrawalGasLimit,
  getExecutionFee,
  getFeeItem,
  getTotalFeeItem,
  useGasLimits,
  useGasPrice,
} from 'domain/synthetics/fees'
import {
  estimateDepositOraclePriceCount,
  estimateWithdrawalOraclePriceCount,
} from 'domain/synthetics/fees/utils/estimateOraclePriceCount'
import { useMarketTokensData } from 'domain/synthetics/markets'
import {
  Market,
  MarketInfo,
  MarketsInfoData,
} from 'domain/synthetics/markets/types'
import {
  getAvailableUsdLiquidityForCollateral,
  getMarketIndexName,
  getMarketPoolName,
  getTokenPoolType,
} from 'domain/synthetics/markets/utils'
import {
  TokenData,
  TokensData,
  convertToUsd,
  getTokenData,
} from 'domain/synthetics/tokens'
import { GmSwapFees, useAvailableTokenOptions } from 'domain/synthetics/trade'
import useSortedMarketsWithIndexToken from 'domain/synthetics/trade/useSortedMarketsWithIndexToken'
import { getDepositAmounts } from 'domain/synthetics/trade/utils/deposit'
import {
  getCommonError,
  getGmSwapError,
} from 'domain/synthetics/trade/utils/validation'
import { getWithdrawalAmounts } from 'domain/synthetics/trade/utils/withdrawal'
import { Token } from 'domain/tokens'
import { BigNumber, ethers } from 'ethers'
import { useChainId } from 'rfx/lib/chains'
import { helperToast } from 'rfx/lib/helperToast'
import { DUST_BNB } from 'rfx/lib/legacy'
import { useLocalStorageSerializeKey } from 'rfx/lib/localStorage'
import {
  formatAmountFree,
  formatTokenAmount,
  formatUsd,
  limitDecimals,
  parseValue,
} from 'rfx/lib/numbers'
import { getByKey } from 'rfx/lib/objects'
import { useSafeState } from 'rfx/lib/useSafeState'
import useIsMetamaskMobile from 'rfx/lib/wallets/useIsMetamaskMobile'
import useWallet from 'rfx/lib/wallets/useWallet'
import useTokenPrice from 'hooks/rfx/usePyth/useTokenPrice'
import { PendingTransaction } from 'hooks/usePaymaster'
import { useViewport } from 'hooks/useViewport'
import { useRouter } from 'next/router'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { isMarketDisabled } from 'utils/markets'
import { GmConfirmationBox } from '../GmConfirmationBox/GmConfirmationBox'
import React from 'react'
import BridgeForm from './BridgeForm'
import { useCreatePoolStore } from '@store/permissionlessCreatePoolStore'

export enum Operation {
  Deposit = 'Deposit',
  Withdrawal = 'Withdrawal',
  Bridge = 'Bridge',
}

export enum Mode {
  Single = 'Single',
  Pair = 'Pair',
}

type Props = {
  selectedMarketAddress?: string
  markets: Market[]
  marketsInfoData?: MarketsInfoData
  tokensData?: TokensData
  onSelectMarket: (marketAddress: string) => void
  setPendingTxns: (txns: PendingTransaction[]) => void
  operation: Operation
  shouldDisableValidation?: boolean
  mode: Mode
  setMode: Dispatch<SetStateAction<Mode>>
  setOperation: Dispatch<SetStateAction<Operation>>
}

const getAvailableModes = (operation: Operation, market?: Market) => {
  if (operation === Operation.Deposit) {
    if (!market?.isSameCollaterals) {
      return [Mode.Single, Mode.Pair]
    }

    return [Mode.Single]
  }

  return [Mode.Pair]
}

function showMarketToast(market: MarketInfo) {
  if (!market) {
    return
  }
  const indexName = getMarketIndexName(market)
  const poolName = getMarketPoolName(market)
  helperToast.success(
    <>
      RP: {indexName} [{poolName}] selected in order form
    </>,
  )
}

export function GmSwapBox(p: Props) {
  const {
    operation,
    mode,
    setMode,
    setOperation,
    onSelectMarket,
    marketsInfoData,
    tokensData,
    shouldDisableValidation,
  } = p

  const { setMarketDetailAddress } = useCreatePoolStore()
  const isMetamaskMobile = useIsMetamaskMobile()
  const router = useRouter()
  const marketAddress = p.selectedMarketAddress

  const { chainId } = useChainId()
  const { account } = useWallet()
  const { isMobile } = useViewport()

  const { gasLimits } = useGasLimits(chainId)
  const { gasPrice } = useGasPrice(chainId)

  const { data: hasOutdatedUi } = useHasOutdatedUi()
  const { marketTokensData: depositMarketTokensData } = useMarketTokensData({
    isDeposit: true,
  })
  const { marketTokensData: withdrawalMarketTokensData } = useMarketTokensData({
    isDeposit: false,
  })

  const [focusedInput, setFocusedInput] = useState<
    'longCollateral' | 'shortCollateral' | 'market'
  >('market')
  const [stage, setStage] = useState<'swap' | 'confirmation' | 'processing'>()
  const [isHighPriceImpactAccepted, setIsHighPriceImpactAccepted] =
    useState(false)
  const { marketsInfo: sortedMarketsInfoByIndexToken } =
    useSortedMarketsWithIndexToken(marketsInfoData, depositMarketTokensData)

  const operationLabels = {
    [Operation.Deposit]: `Add Liquidity`,
    [Operation.Withdrawal]: `Remove Liquidity`,
    [Operation.Bridge]: `Bridge`,
  }

  const isDeposit = operation === Operation.Deposit
  const isWithdrawal = operation === Operation.Withdrawal
  const isSingle = mode === Mode.Single
  const isPair = mode === Mode.Pair

  const marketTokensData = isDeposit
    ? depositMarketTokensData
    : withdrawalMarketTokensData
  const markets = useMemo(
    () =>
      Object.values(marketsInfoData || {}).filter(
        (marketInfo) => !isMarketDisabled(marketInfo),
      ),
    [marketsInfoData],
  )
  const marketInfo = getByKey(marketsInfoData, marketAddress)
  const availableModes = getAvailableModes(operation, marketInfo)

  const [indexName, setIndexName] = useLocalStorageSerializeKey<
    string | undefined
  >(getSyntheticsDepositIndexTokenKey(chainId), undefined)
  const { infoTokens } = useAvailableTokenOptions()

  const [firstTokenAddress, setFirstTokenAddress] = useLocalStorageSerializeKey<
    string | undefined
  >(
    [
      chainId,
      SYNTHETICS_MARKET_DEPOSIT_TOKEN_KEY,
      isDeposit,
      marketAddress,
      'first',
    ],
    undefined,
  )
  const firstToken = getTokenData(tokensData, firstTokenAddress)

  const ethToken = getTokenData(tokensData, ethers.constants.AddressZero)

  const firstTokenPrice = useTokenPrice(
    firstToken?.pythId || '',
    firstToken?.address || '',
    'maxPrice',
  )

  const [firstTokenInputValue, setFirstTokenInputValue] =
    useSafeState<string>('')
  const firstTokenAmount = parseValue(
    firstTokenInputValue,
    firstToken?.decimals || 0,
  )
  const firstTokenUsd = useMemo(
    () => convertToUsd(firstTokenAmount, firstToken?.decimals, firstTokenPrice),
    [firstTokenPrice, firstTokenAmount, firstToken?.decimals],
  )

  const [secondTokenAddress, setSecondTokenAddress] =
    useLocalStorageSerializeKey<string | undefined>(
      [
        chainId,
        SYNTHETICS_MARKET_DEPOSIT_TOKEN_KEY,
        isDeposit,
        marketAddress,
        'second',
      ],
      undefined,
    )
  const secondToken = getTokenData(tokensData, secondTokenAddress)
  const secondTokenPrice = useTokenPrice(
    secondToken?.pythId || '',
    secondToken?.address || '',
    'maxPrice',
  )
  const [secondTokenInputValue, setSecondTokenInputValue] =
    useSafeState<string>('')
  const secondTokenAmount = parseValue(
    secondTokenInputValue,
    secondToken?.decimals || 0,
  )
  const secondTokenUsd = useMemo(
    () =>
      convertToUsd(secondTokenAmount, secondToken?.decimals, secondTokenPrice),
    [secondTokenPrice, secondToken?.decimals, secondTokenAmount],
  )

  const { longTokenInputState, shortTokenInputState } = useMemo(() => {
    if (!marketInfo) {
      return {}
    }

    const inputs: {
      address: string
      value: string
      amount?: BigNumber
      usd?: BigNumber
      token?: TokenData
      setValue: (val: string) => void
    }[] = []

    if (firstTokenAddress) {
      inputs.push({
        address: firstTokenAddress,
        value: firstTokenInputValue,
        setValue: setFirstTokenInputValue,
        amount: firstTokenAmount,
        usd: firstTokenUsd,
        token: firstToken,
      })
    }

    if ((isWithdrawal || isPair) && secondTokenAddress) {
      inputs.push({
        address: secondTokenAddress,
        value: secondTokenInputValue,
        setValue: setSecondTokenInputValue,
        amount: secondTokenAmount,
        usd: secondTokenUsd,
        token: secondToken,
      })
    }

    const longTokenInputState = inputs.find(
      (input) => getTokenPoolType(marketInfo, input.address) === 'long',
    )
    const shortTokenInputState = inputs.find(
      (input) => getTokenPoolType(marketInfo, input.address) === 'short',
    )

    return {
      longTokenInputState,
      shortTokenInputState,
    }
  }, [
    firstToken,
    firstTokenAddress,
    firstTokenAmount,
    firstTokenInputValue,
    firstTokenUsd,
    isPair,
    isWithdrawal,
    marketInfo,
    secondToken,
    secondTokenAddress,
    secondTokenAmount,
    secondTokenInputValue,
    secondTokenUsd,
    setFirstTokenInputValue,
    setSecondTokenInputValue,
  ])

  const tokenOptions: Token[] = useMemo(() => {
    const { longToken, shortToken } = marketInfo || {}

    if (!longToken || !shortToken) {
      return []
    }

    const result = [longToken]

    if (longToken.address !== shortToken.address) {
      result.push(shortToken)
    }

    const nativeToken = getByKey(tokensData, NATIVE_TOKEN_ADDRESS)!

    if (result.some((token) => token.isWrapped) && nativeToken) {
      result.unshift(nativeToken)
    }

    return result
  }, [marketInfo, tokensData])

  const [marketTokenInputValue, setMarketTokenInputValue] =
    useSafeState<string>()
  const marketToken = getTokenData(
    isDeposit ? depositMarketTokensData : withdrawalMarketTokensData,
    marketInfo?.marketTokenAddress,
  )

  const marketTokenAmount = parseValue(
    marketTokenInputValue || '0',
    marketToken?.decimals || 0,
  )!
  const marketTokenUsd = useMemo(
    () =>
      convertToUsd(
        marketTokenAmount,
        marketToken?.decimals,
        marketToken?.prices?.maxPrice,
      )!,
    [marketTokenAmount, marketToken?.decimals, marketToken?.prices?.maxPrice],
  )

  const { longCollateralLiquidityUsd, shortCollateralLiquidityUsd } =
    useMemo(() => {
      if (!marketInfo) {
        return {}
      }

      return {
        longCollateralLiquidityUsd: getAvailableUsdLiquidityForCollateral(
          marketInfo,
          true,
        ),
        shortCollateralLiquidityUsd: getAvailableUsdLiquidityForCollateral(
          marketInfo,
          false,
        ),
      }
    }, [marketInfo])

  const depositAmounts = useMemo(() => {
    if (!isDeposit || !marketInfo || !marketToken) {
      return undefined
    }

    return getDepositAmounts({
      marketInfo,
      marketToken,
      longToken: marketInfo.longToken,
      shortToken: marketInfo.shortToken,
      longTokenAmount: longTokenInputState?.amount || BigNumber.from(0),
      shortTokenAmount: shortTokenInputState?.amount || BigNumber.from(0),
      marketTokenAmount,
      includeLongToken: Boolean(longTokenInputState?.address),
      includeShortToken: Boolean(shortTokenInputState?.address),
      strategy: focusedInput === 'market' ? 'byMarketToken' : 'byCollaterals',
    })
  }, [
    focusedInput,
    isDeposit,
    longTokenInputState?.address,
    longTokenInputState?.amount,
    marketInfo,
    marketToken,
    marketTokenAmount,
    shortTokenInputState?.address,
    shortTokenInputState?.amount,
  ])

  const withdrawalAmounts = useMemo(() => {
    if (!isWithdrawal || !marketInfo || !marketToken) {
      return undefined
    }

    let strategy: 'byMarketToken' | 'byLongCollateral' | 'byShortCollateral'
    if (focusedInput === 'market') {
      strategy = 'byMarketToken'
    } else if (focusedInput === 'longCollateral') {
      strategy = 'byLongCollateral'
    } else {
      strategy = 'byShortCollateral'
    }

    return getWithdrawalAmounts({
      marketInfo,
      marketToken,
      marketTokenAmount,
      longTokenAmount: longTokenInputState?.amount || BigNumber.from(0),
      shortTokenAmount: shortTokenInputState?.amount || BigNumber.from(0),
      strategy,
    })
  }, [
    focusedInput,
    isWithdrawal,
    longTokenInputState?.amount,
    marketInfo,
    marketToken,
    marketTokenAmount,
    shortTokenInputState?.amount,
  ])

  const amounts = isDeposit ? depositAmounts : withdrawalAmounts

  const { fees, executionFee } = useMemo(() => {
    if (!gasLimits || gasPrice === undefined || !tokensData || !amounts) {
      return {}
    }

    const basisUsd = isDeposit
      ? (amounts?.longTokenUsd).add(amounts?.shortTokenUsd)
      : amounts?.marketTokenUsd || BigNumber.from(0)

    const swapFee = getFeeItem(
      amounts.swapFeeUsd.mul(BigNumber.from(-1)),
      basisUsd,
    )
    const swapPriceImpact = getFeeItem(
      amounts.swapPriceImpactDeltaUsd,
      basisUsd,
    )

    const totalFees = getTotalFeeItem(
      [swapPriceImpact, swapFee].filter(Boolean) as FeeItem[],
    )
    const fees: GmSwapFees = {
      swapFee,
      swapPriceImpact,
      totalFees,
    }

    const gasLimit = isDeposit
      ? estimateExecuteDepositGasLimit(gasLimits, {
          initialLongTokenAmount: amounts.longTokenAmount,
          initialShortTokenAmount: amounts.shortTokenAmount,
        })
      : estimateExecuteWithdrawalGasLimit(gasLimits, {})

    const oraclePriceCount = isDeposit
      ? estimateDepositOraclePriceCount(0)
      : estimateWithdrawalOraclePriceCount(0)

    const executionFee = getExecutionFee(
      chainId,
      gasLimits,
      tokensData,
      gasLimit,
      gasPrice,
      oraclePriceCount,
    )

    return {
      fees,
      executionFee,
    }
  }, [amounts, chainId, gasLimits, gasPrice, isDeposit, tokensData])

  const isHighPriceImpact =
    fees?.swapPriceImpact?.deltaUsd.lt(0) &&
    fees.swapPriceImpact.bps.abs().gte(HIGH_PRICE_IMPACT_BPS)

  const submitState = useMemo(() => {
    const commonError = getCommonError({
      chainId,
      isConnected: true,
      hasOutdatedUi,
    })[0]

    const swapError = getGmSwapError({
      isDeposit,
      marketInfo,
      marketToken,
      longToken: longTokenInputState?.token,
      shortToken: shortTokenInputState?.token,
      marketTokenAmount,
      marketTokenUsd: amounts?.marketTokenUsd,
      longTokenAmount: amounts?.longTokenAmount,
      shortTokenAmount: amounts?.shortTokenAmount,
      longTokenUsd: amounts?.longTokenUsd,
      shortTokenUsd: amounts?.shortTokenUsd,
      longTokenLiquidityUsd: longCollateralLiquidityUsd,
      shortTokenLiquidityUsd: shortCollateralLiquidityUsd,
      fees,
      isHighPriceImpact: Boolean(isHighPriceImpact),
      isHighPriceImpactAccepted,
    })[0]

    const error = commonError || swapError

    if (!account) {
      return {
        text: `Connect Wallet`,
        isDisabled: true,
        onSubmit: () => {},
      }
    }

    const onSubmit = () => {
      setStage('confirmation')
    }

    if (error) {
      return {
        text: error,
        error,
        isDisabled: !shouldDisableValidation,
        onSubmit,
      }
    }

    return {
      text: isDeposit ? `Buy RP` : `Sell RP`,
      onSubmit,
    }
  }, [
    account,
    amounts?.longTokenAmount,
    amounts?.longTokenUsd,
    amounts?.marketTokenUsd,
    amounts?.shortTokenAmount,
    amounts?.shortTokenUsd,
    chainId,
    fees,
    hasOutdatedUi,
    isDeposit,
    isHighPriceImpact,
    isHighPriceImpactAccepted,
    longCollateralLiquidityUsd,
    longTokenInputState?.token,
    marketInfo,
    marketToken,
    marketTokenAmount,
    shortCollateralLiquidityUsd,
    shortTokenInputState?.token,
    shouldDisableValidation,
  ])

  function onFocusedCollateralInputChange(tokenAddress: string) {
    if (!marketInfo) {
      return
    }

    if (marketInfo?.isSameCollaterals) {
      setFocusedInput('shortCollateral')
      return
    }

    if (getTokenPoolType(marketInfo, tokenAddress) === 'long') {
      setFocusedInput('longCollateral')
    } else {
      setFocusedInput('shortCollateral')
    }
  }

  useEffect(() => {
    setFirstTokenInputValue('')
    setSecondTokenInputValue('')
    setMarketTokenInputValue('')
  }, [firstTokenAddress, secondTokenAddress])

  const resetInputs = useCallback(() => {
    setFirstTokenInputValue('')
    setSecondTokenInputValue('')
    setMarketTokenInputValue('')
  }, [
    setFirstTokenInputValue,
    setMarketTokenInputValue,
    setSecondTokenInputValue,
    firstTokenAddress,
    secondTokenAddress,
  ])

  const onOperationChange = useCallback(
    (operation: Operation) => {
      resetInputs()
      setOperation(operation)
    },
    [resetInputs, setOperation],
  )

  const onMarketChange = useCallback(
    (marketAddress: string) => {
      resetInputs()
      onSelectMarket(marketAddress)
      setMarketDetailAddress(marketAddress)
    },
    [onSelectMarket, resetInputs],
  )

  useEffect(
    function updateInputAmounts() {
      if (!marketToken || !marketInfo) {
        return
      }

      const longToken = longTokenInputState?.token
      const shortToken = shortTokenInputState?.token

      if (isDeposit) {
        if (['longCollateral', 'shortCollateral'].includes(focusedInput)) {
          if (!amounts?.longTokenUsd?.gt(0) && !amounts?.shortTokenUsd?.gt(0)) {
            setMarketTokenInputValue('')
            return
          }

          if (amounts) {
            setMarketTokenInputValue(
              amounts.marketTokenAmount.gt(0)
                ? formatAmountFree(
                    amounts.marketTokenAmount,
                    marketToken.decimals,
                  )
                : '',
            )
          }
        } else if (focusedInput === 'market') {
          if (!marketTokenAmount?.gt(0)) {
            longTokenInputState?.setValue('')
            shortTokenInputState?.setValue('')
            return
          }

          if (amounts) {
            if (longToken) {
              longTokenInputState?.setValue(
                amounts.longTokenAmount?.gt(0)
                  ? formatAmountFree(
                      amounts.longTokenAmount,
                      longToken.decimals,
                    )
                  : '',
              )
            }
            if (shortToken) {
              shortTokenInputState?.setValue(
                amounts.shortTokenAmount?.gt(0)
                  ? formatAmountFree(
                      amounts.shortTokenAmount,
                      shortToken.decimals,
                    )
                  : '',
              )
            }
            return
          }
        }
      }

      if (isWithdrawal) {
        if (focusedInput === 'market') {
          if (!amounts?.marketTokenAmount?.gt(0)) {
            longTokenInputState?.setValue('')
            shortTokenInputState?.setValue('')
            return
          }

          if (amounts) {
            if (marketInfo.isSameCollaterals) {
              setFirstTokenInputValue(
                amounts.longTokenAmount?.gt(0)
                  ? formatAmountFree(
                      amounts.longTokenAmount,
                      longToken!.decimals,
                    )
                  : '',
              )
              setSecondTokenInputValue(
                amounts.shortTokenAmount?.gt(0)
                  ? formatAmountFree(
                      amounts.shortTokenAmount,
                      shortToken!.decimals,
                    )
                  : '',
              )
            } else {
              longTokenInputState?.setValue(
                amounts.longTokenAmount?.gt(0)
                  ? formatAmountFree(
                      amounts.longTokenAmount,
                      longToken!.decimals,
                    )
                  : '',
              )
              shortTokenInputState?.setValue(
                amounts.shortTokenAmount?.gt(0)
                  ? formatAmountFree(
                      amounts.shortTokenAmount,
                      shortToken!.decimals,
                    )
                  : '',
              )
            }
          }
        } else if (
          ['longCollateral', 'shortCollateral'].includes(focusedInput)
        ) {
          if (
            focusedInput === 'longCollateral' &&
            !amounts?.longTokenAmount?.gt(0)
          ) {
            shortTokenInputState?.setValue('')
            setMarketTokenInputValue('')
            return
          }

          if (
            focusedInput === 'shortCollateral' &&
            !amounts?.shortTokenAmount?.gt(0)
          ) {
            longTokenInputState?.setValue('')
            setMarketTokenInputValue('')
            return
          }

          if (amounts) {
            setMarketTokenInputValue(
              amounts.marketTokenAmount.gt(0)
                ? formatAmountFree(
                    amounts.marketTokenAmount,
                    marketToken.decimals,
                  )
                : '',
            )

            if (amounts.longTokenAmount) {
              if (marketInfo.isSameCollaterals) {
                setFirstTokenInputValue(
                  amounts.longTokenAmount.gt(0)
                    ? formatAmountFree(
                        amounts.longTokenAmount,
                        longToken!.decimals,
                      )
                    : '',
                )
              } else {
                longTokenInputState?.setValue(
                  amounts.longTokenAmount.gt(0)
                    ? formatAmountFree(
                        amounts.longTokenAmount,
                        longToken!.decimals,
                      )
                    : '',
                )
              }
            }
            if (amounts.shortTokenAmount) {
              if (marketInfo.isSameCollaterals) {
                setSecondTokenInputValue(
                  amounts.shortTokenAmount.gt(0)
                    ? formatAmountFree(
                        amounts.shortTokenAmount,
                        shortToken!.decimals,
                      )
                    : '',
                )
              } else {
                shortTokenInputState?.setValue(
                  amounts.shortTokenAmount.gt(0)
                    ? formatAmountFree(
                        amounts.shortTokenAmount,
                        shortToken!.decimals,
                      )
                    : '',
                )
              }
            }
          }
        }
      }
    },
    [
      amounts,
      focusedInput,
      isDeposit,
      isWithdrawal,
      longTokenInputState,
      marketInfo,
      marketToken,
      marketTokenAmount,
      setFirstTokenInputValue,
      setMarketTokenInputValue,
      setSecondTokenInputValue,
      shortTokenInputState,
    ],
  )

  useEffect(
    function updateIndexToken() {
      if (!indexName && markets.length) {
        setIndexName(getMarketIndexName(markets[0]))
      }
    },
    [indexName, markets, setIndexName],
  )

  useEffect(
    function updateMarket() {
      const marketsByIndexName = markets.filter(
        (market) => getMarketIndexName(market) === indexName,
      )

      if (!marketsByIndexName.length) {
        return
      }

      if (
        !marketAddress ||
        !marketsByIndexName.find(
          (market) => market.marketTokenAddress === marketAddress,
        )
      ) {
        onMarketChange(marketsByIndexName[0].marketTokenAddress)
      }
    },
    [indexName, marketAddress, markets, onMarketChange],
  )

  useEffect(() => {
    if (router.query.operation === Operation.Withdrawal) {
      setOperation(Operation.Withdrawal)
    } else if (router.query.operation === Operation.Deposit) {
      setOperation(Operation.Deposit)
    }

    if (router.query.market) {
      const marketInfo = getByKey(
        marketsInfoData,
        router.query.market as string,
      )
      if (marketInfo) {
        setIndexName(getMarketIndexName(marketInfo))
        onSelectMarket(marketInfo?.marketTokenAddress)
      }

      if (router.query.scroll === '1') {
        window.scrollTo({ top: 0, left: 0 })
      }
    }
  }, [
    marketsInfoData,
    onSelectMarket,
    router.query,
    setIndexName,
    setOperation,
  ])

  useEffect(
    function updateTokens() {
      if (!tokenOptions.length) {
        return
      }

      if (!tokenOptions.find((token) => token.address === firstTokenAddress)) {
        setFirstTokenAddress(tokenOptions[0].address)
      }

      if (
        isSingle &&
        secondTokenAddress &&
        marketInfo &&
        secondTokenAmount?.gt(0)
      ) {
        const secondTokenPoolType = getTokenPoolType(
          marketInfo,
          secondTokenAddress,
        )
        setFocusedInput(
          secondTokenPoolType === 'long' ? 'longCollateral' : 'shortCollateral',
        )
        setSecondTokenAddress(undefined)
        setSecondTokenInputValue('')
        return
      }

      if (isPair && firstTokenAddress) {
        if (marketInfo?.isSameCollaterals) {
          if (!secondTokenAddress || firstTokenAddress !== secondTokenAddress) {
            setSecondTokenAddress(firstTokenAddress)
          }

          return
        }

        if (
          !secondTokenAddress ||
          !tokenOptions.find((token) => token.address === secondTokenAddress) ||
          convertTokenAddress(chainId, firstTokenAddress, 'wrapped') ===
            convertTokenAddress(chainId, secondTokenAddress, 'wrapped')
        ) {
          const secondToken = tokenOptions.find((token) => {
            return (
              convertTokenAddress(chainId, token.address, 'wrapped') !==
              convertTokenAddress(chainId, firstTokenAddress, 'wrapped')
            )
          })
          setSecondTokenAddress(secondToken?.address)
        }
      }
    },
    [
      chainId,
      firstTokenAddress,
      isPair,
      isSingle,
      marketInfo,
      secondTokenAddress,
      secondTokenAmount,
      setFirstTokenAddress,
      setSecondTokenAddress,
      setSecondTokenInputValue,
      tokenOptions,
    ],
  )

  if (operation === Operation.Bridge) {
    return (
      <>
        <div className="p-[10px] !px-4 pt-0">
          <div className={isMobile ? '' : 'pt-4'}>
            <TabUnderline
              activeValue={operation}
              values={[
                Operation.Deposit,
                Operation.Withdrawal,
                Operation.Bridge,
              ]}
              names={[
                operationLabels[Operation.Deposit],
                operationLabels[Operation.Withdrawal],
                operationLabels[Operation.Bridge],
              ]}
              onChange={onOperationChange}
              small
            />
          </div>
          <BridgeForm />
        </div>
      </>
    )
  }

  return (
    <div className="p-[10px] !px-4 pt-0">
      {stage !== 'confirmation' && (
        <div>
          <div className={isMobile ? '' : 'pt-4'}>
            <TabUnderline
              activeValue={operation}
              values={[
                Operation.Deposit,
                Operation.Withdrawal,
                Operation.Bridge,
              ]}
              names={[
                operationLabels[Operation.Deposit],
                operationLabels[Operation.Withdrawal],
                operationLabels[Operation.Bridge],
              ]}
              onChange={onOperationChange}
              small
            />
          </div>

          {operation !== 'Withdrawal' && (
            <div className="pt-4">
              <ButtonGroup
                activeValue={mode}
                className="uppercase"
                onChange={setMode}
                values={availableModes}
              />
            </div>
          )}
          <form
            onSubmit={(e) => {
              e.preventDefault()
              submitState?.onSubmit()
            }}
          >
            <div
              className={`flex flex-col`}
              style={{ flexDirection: isDeposit ? 'column-reverse' : 'column' }}
            >
              <BuyInputSection
                topLeftLabel={isWithdrawal ? `Pay` : `Receive`}
                topLeftValue={
                  marketTokenUsd?.gt(0) ? formatUsd(marketTokenUsd) : ''
                }
                topRightLabel={`Balance`}
                topRightValue={formatTokenAmount(
                  marketToken?.balance,
                  marketToken?.decimals,
                  '',
                  {
                    useCommas: true,
                  },
                )}
                showMaxButton={
                  isWithdrawal &&
                  marketToken?.balance?.gt(0) &&
                  !marketTokenAmount?.eq(marketToken.balance)
                }
                inputValue={marketTokenInputValue}
                onInputValueChange={(e) => {
                  setMarketTokenInputValue(e.target.value)
                  setFocusedInput('market')
                }}
                {...(isWithdrawal && {
                  onClickTopRightLabel: () => {
                    if (marketToken?.balance) {
                      setMarketTokenInputValue(
                        formatAmountFree(
                          marketToken.balance,
                          marketToken.decimals,
                        ),
                      )
                      setFocusedInput('market')
                    }
                  },
                })}
                onClickMax={() => {
                  if (marketToken?.balance) {
                    const formattedGMBalance = formatAmountFree(
                      marketToken.balance,
                      marketToken.decimals,
                    )
                    const finalGMBalance = isMetamaskMobile
                      ? limitDecimals(
                          formattedGMBalance,
                          MAX_METAMASK_MOBILE_DECIMALS,
                        )
                      : formattedGMBalance
                    setMarketTokenInputValue(finalGMBalance)
                    setFocusedInput('market')
                  }
                }}
              >
                <PoolSelector
                  label={`Pool`}
                  selectedIndexName={indexName}
                  selectedMarketAddress={marketAddress}
                  markets={sortedMarketsInfoByIndexToken}
                  marketTokensData={marketTokensData}
                  marketsInfoData={marketsInfoData}
                  isSideMenu
                  showBalances
                  showAllPools
                  showIndexIcon
                  onSelectMarket={(marketInfo) => {
                    setIndexName(getMarketIndexName(marketInfo))
                    onMarketChange(marketInfo.marketTokenAddress)
                  }}
                  inputFieldValue={marketTokenInputValue}
                />
              </BuyInputSection>

              <BuyInputSection
                topLeftLabel={isDeposit ? `Pay` : `Receive`}
                topLeftValue={formatUsd(firstTokenUsd)}
                topRightLabel={`Balance`}
                topRightValue={formatTokenAmount(
                  firstToken?.balance,
                  firstToken?.decimals,
                  '',
                  {
                    useCommas: true,
                  },
                )}
                {...(isDeposit && {
                  onClickTopRightLabel: () => {
                    if (firstToken?.balance) {
                      const maxAvailableAmount = firstToken.isNative
                        ? firstToken.balance.sub(
                            BigNumber.from(DUST_BNB).mul(2),
                          )
                        : firstToken.balance
                      setFirstTokenInputValue(
                        formatAmountFree(
                          maxAvailableAmount,
                          firstToken.decimals,
                        ),
                      )
                      onFocusedCollateralInputChange(firstToken.address)
                    }
                  },
                })}
                showMaxButton={
                  isDeposit &&
                  firstToken?.balance?.gt(0) &&
                  !firstTokenAmount?.eq(firstToken.balance)
                }
                inputValue={firstTokenInputValue}
                onInputValueChange={(e) => {
                  if (!firstToken) {
                    return
                  }

                  setFirstTokenInputValue(e.target.value)
                  onFocusedCollateralInputChange(firstToken.address)

                  const hasSecondToken =
                    mode === 'Pair' && secondToken && ethToken

                  if (!hasSecondToken) {
                    return
                  }

                  const inputValue = +(e.target.value || '0')

                  const firstTokenPrice = +ethers.utils.formatUnits(
                    firstToken?.prices.maxPrice,
                    30,
                  )
                  const secondTokenPrice = +ethers.utils.formatUnits(
                    secondToken?.prices.maxPrice,
                    30,
                  )
                  setSecondTokenInputValue(
                    (
                      inputValue *
                      (firstTokenPrice / secondTokenPrice)
                    ).toString(),
                  )
                }}
                onClickMax={() => {
                  if (firstToken?.balance) {
                    const maxAvailableAmount = firstToken.isNative
                      ? firstToken.balance.sub(BigNumber.from(DUST_BNB).mul(2))
                      : firstToken.balance

                    const formattedMaxAvailableAmount = formatAmountFree(
                      maxAvailableAmount,
                      firstToken.decimals,
                    )
                    const finalAmount = isMetamaskMobile
                      ? limitDecimals(
                          formattedMaxAvailableAmount,
                          MAX_METAMASK_MOBILE_DECIMALS,
                        )
                      : formattedMaxAvailableAmount

                    setFirstTokenInputValue(finalAmount)
                    onFocusedCollateralInputChange(firstToken.address)
                  }
                }}
              >
                {firstTokenAddress && isSingle ? (
                  <TokenSelector
                    label={`Pay`}
                    chainId={chainId}
                    tokenAddress={firstTokenAddress}
                    onSelectToken={(token) =>
                      setFirstTokenAddress(token.address)
                    }
                    tokens={tokenOptions}
                    infoTokens={infoTokens}
                    showSymbolImage={true}
                    showTokenImgInDropdown={true}
                    inputFieldValue={firstTokenInputValue}
                  />
                ) : (
                  <div className="flex items-center gap-2">
                    <RenderTokenIcon
                      symbol={firstToken?.symbol}
                      size="small"
                      state={firstTokenInputValue ? 'active' : 'default'}
                    />
                    <span>{firstToken?.symbol}</span>
                  </div>
                )}
              </BuyInputSection>

              {isPair && secondTokenAddress && (
                <BuyInputSection
                  topLeftLabel={isDeposit ? `Pay` : `Receive`}
                  topLeftValue={formatUsd(secondTokenUsd)}
                  topRightLabel={`Balance`}
                  topRightValue={formatTokenAmount(
                    secondToken?.balance,
                    secondToken?.decimals,
                    '',
                    {
                      useCommas: true,
                    },
                  )}
                  inputValue={secondTokenInputValue}
                  showMaxButton={
                    isDeposit &&
                    secondToken?.balance?.gt(0) &&
                    !secondTokenAmount?.eq(secondToken.balance)
                  }
                  onInputValueChange={(e) => {
                    if (!secondToken) {
                      return
                    }

                    setSecondTokenInputValue(e.target.value)
                    onFocusedCollateralInputChange(secondToken.address)

                    const hasFirstToken =
                      mode === 'Pair' && firstToken && ethToken

                    if (!hasFirstToken) {
                      return
                    }

                    const inputValue = +(e.target.value || '0')
                    const firstTokenPrice = +ethers.utils.formatUnits(
                      firstToken?.prices.maxPrice,
                      30,
                    )
                    const secondTokenPrice = +ethers.utils.formatUnits(
                      secondToken?.prices.maxPrice,
                      30,
                    )
                    setFirstTokenInputValue(
                      (
                        inputValue *
                        (secondTokenPrice / firstTokenPrice)
                      ).toString(),
                    )
                  }}
                  {...(isDeposit && {
                    onClickTopRightLabel: () => {
                      if (secondToken?.balance) {
                        const maxAvailableAmount = secondToken.isNative
                          ? secondToken.balance.sub(
                              BigNumber.from(DUST_BNB).mul(2),
                            )
                          : secondToken.balance
                        setSecondTokenInputValue(
                          formatAmountFree(
                            maxAvailableAmount,
                            secondToken.decimals,
                          ),
                        )
                        onFocusedCollateralInputChange(secondToken.address)
                      }
                    },
                  })}
                  onClickMax={() => {
                    if (secondToken?.balance) {
                      const maxAvailableAmount = secondToken.isNative
                        ? secondToken.balance.sub(
                            BigNumber.from(DUST_BNB).mul(2),
                          )
                        : secondToken.balance

                      const formattedMaxAvailableAmount = formatAmountFree(
                        maxAvailableAmount,
                        secondToken.decimals,
                      )
                      const finalAmount = isMetamaskMobile
                        ? limitDecimals(
                            formattedMaxAvailableAmount,
                            MAX_METAMASK_MOBILE_DECIMALS,
                          )
                        : formattedMaxAvailableAmount
                      setSecondTokenInputValue(finalAmount)
                      onFocusedCollateralInputChange(secondToken.address)
                    }
                  }}
                >
                  <div className="flex items-center gap-2">
                    <RenderTokenIcon
                      symbol={secondToken?.symbol}
                      size="small"
                      state={secondTokenInputValue ? 'active' : 'default'}
                    />
                    <span>{secondToken?.symbol}</span>
                  </div>
                </BuyInputSection>
              )}
            </div>

            {isHighPriceImpact && (
              <>
                <div className="mt-4" />
                <Checkbox
                  reverseOrder
                  isChecked={isHighPriceImpactAccepted}
                  setIsChecked={setIsHighPriceImpactAccepted}
                >
                  {isSingle ? (
                    <Tooltip
                      content={
                        <div>{`Consider selecting and using the "Pair" option to reduce the Price Impact.`}</div>
                      }
                    >
                      {<>Acknowledge high Price Impact</>}
                    </Tooltip>
                  ) : (
                    <span className="font-sm">
                      <>Acknowledge high Price Impact</>
                    </span>
                  )}
                </Checkbox>
              </>
            )}

            <div className="Exchange-swap-button-container mt-4">
              <Button
                className={`w-full uppercase ${
                  submitState.text === 'Connect Wallet' && 'connect-wallet'
                }`}
                onClick={submitState.onSubmit}
                disabled={submitState.isDisabled}
                size="large"
              >
                {submitState.text}
              </Button>
            </div>
          </form>
        </div>
      )}

      {stage === 'confirmation' && (
        <GmConfirmationBox
          isVisible={stage === 'confirmation'}
          marketToken={marketToken!}
          setStage={setStage}
          receiveComponent={
            <BuyInputSection
              topLeftLabel={isWithdrawal ? `Pay` : `Receive`}
              topLeftValue={
                marketTokenUsd?.gt(0) ? formatUsd(marketTokenUsd) : ''
              }
              topRightLabel={`Balance`}
              topRightValue={formatTokenAmount(
                marketToken?.balance,
                marketToken?.decimals,
                '',
                {
                  useCommas: true,
                },
              )}
              showMaxButton={
                isWithdrawal &&
                marketToken?.balance?.gt(0) &&
                !marketTokenAmount?.eq(marketToken.balance)
              }
              inputValue={marketTokenInputValue}
              onInputValueChange={(e) => {
                setMarketTokenInputValue(e.target.value)
                setFocusedInput('market')
              }}
              {...(isWithdrawal && {
                onClickTopRightLabel: () => {
                  if (marketToken?.balance) {
                    setMarketTokenInputValue(
                      formatAmountFree(
                        marketToken.balance,
                        marketToken.decimals,
                      ),
                    )
                    setFocusedInput('market')
                  }
                },
              })}
              onClickMax={() => {
                if (marketToken?.balance) {
                  const formattedGMBalance = formatAmountFree(
                    marketToken.balance,
                    marketToken.decimals,
                  )
                  const finalGMBalance = isMetamaskMobile
                    ? limitDecimals(
                        formattedGMBalance,
                        MAX_METAMASK_MOBILE_DECIMALS,
                      )
                    : formattedGMBalance
                  setMarketTokenInputValue(finalGMBalance)
                  setFocusedInput('market')
                }
              }}
            >
              <PoolSelector
                label={`Pool`}
                selectedIndexName={indexName}
                selectedMarketAddress={marketAddress}
                markets={sortedMarketsInfoByIndexToken}
                marketTokensData={marketTokensData}
                marketsInfoData={marketsInfoData}
                isSideMenu
                showBalances
                showAllPools
                showIndexIcon
                onSelectMarket={(marketInfo) => {
                  setIndexName(getMarketIndexName(marketInfo))
                  onMarketChange(marketInfo.marketTokenAddress)
                  showMarketToast(marketInfo)
                }}
                inputFieldValue={marketTokenInputValue}
              />
            </BuyInputSection>
          }
          payComponent={
            <BuyInputSection
              topLeftLabel={isDeposit ? `Pay` : `Receive`}
              topLeftValue={formatUsd(firstTokenUsd)}
              topRightLabel={`Balance`}
              topRightValue={formatTokenAmount(
                firstToken?.balance,
                firstToken?.decimals,
                '',
                {
                  useCommas: true,
                },
              )}
              {...(isDeposit && {
                onClickTopRightLabel: () => {
                  if (firstToken?.balance) {
                    const maxAvailableAmount = firstToken.isNative
                      ? firstToken.balance.sub(BigNumber.from(DUST_BNB).mul(2))
                      : firstToken.balance
                    setFirstTokenInputValue(
                      formatAmountFree(maxAvailableAmount, firstToken.decimals),
                    )
                    onFocusedCollateralInputChange(firstToken.address)
                  }
                },
              })}
              showMaxButton={
                isDeposit &&
                firstToken?.balance?.gt(0) &&
                !firstTokenAmount?.eq(firstToken.balance)
              }
              inputValue={firstTokenInputValue}
              onInputValueChange={(e) => {
                if (firstToken) {
                  setFirstTokenInputValue(e.target.value)
                  onFocusedCollateralInputChange(firstToken.address)
                }
              }}
              onClickMax={() => {
                if (firstToken?.balance) {
                  const maxAvailableAmount = firstToken.isNative
                    ? firstToken.balance.sub(BigNumber.from(DUST_BNB).mul(2))
                    : firstToken.balance

                  const formattedMaxAvailableAmount = formatAmountFree(
                    maxAvailableAmount,
                    firstToken.decimals,
                  )
                  const finalAmount = isMetamaskMobile
                    ? limitDecimals(
                        formattedMaxAvailableAmount,
                        MAX_METAMASK_MOBILE_DECIMALS,
                      )
                    : formattedMaxAvailableAmount

                  setFirstTokenInputValue(finalAmount)
                  onFocusedCollateralInputChange(firstToken.address)
                }
              }}
            >
              {firstTokenAddress && isSingle ? (
                <TokenSelector
                  label={`Pay`}
                  chainId={chainId}
                  tokenAddress={firstTokenAddress}
                  onSelectToken={(token) => setFirstTokenAddress(token.address)}
                  tokens={tokenOptions}
                  infoTokens={infoTokens}
                  showSymbolImage={true}
                  showTokenImgInDropdown={true}
                  inputFieldValue={firstTokenInputValue}
                />
              ) : (
                <div className="flex items-center gap-2">
                  <RenderTokenIcon
                    symbol={firstToken?.symbol}
                    size="small"
                    state={firstTokenInputValue ? 'active' : 'default'}
                  />
                  <span>{firstToken?.symbol}</span>
                </div>
              )}
            </BuyInputSection>
          }
          pairComponent={
            isPair &&
            secondTokenAddress && (
              <BuyInputSection
                topLeftLabel={isDeposit ? `Pay` : `Receive`}
                topLeftValue={formatUsd(secondTokenUsd)}
                topRightLabel={`Balance`}
                topRightValue={formatTokenAmount(
                  secondToken?.balance,
                  secondToken?.decimals,
                  '',
                  {
                    useCommas: true,
                  },
                )}
                inputValue={secondTokenInputValue}
                showMaxButton={
                  isDeposit &&
                  secondToken?.balance?.gt(0) &&
                  !secondTokenAmount?.eq(secondToken.balance)
                }
                onInputValueChange={(e) => {
                  if (secondToken) {
                    setSecondTokenInputValue(e.target.value)
                    onFocusedCollateralInputChange(secondToken.address)
                  }
                }}
                {...(isDeposit && {
                  onClickTopRightLabel: () => {
                    if (secondToken?.balance) {
                      const maxAvailableAmount = secondToken.isNative
                        ? secondToken.balance.sub(
                            BigNumber.from(DUST_BNB).mul(2),
                          )
                        : secondToken.balance
                      setSecondTokenInputValue(
                        formatAmountFree(
                          maxAvailableAmount,
                          secondToken.decimals,
                        ),
                      )
                      onFocusedCollateralInputChange(secondToken.address)
                    }
                  },
                })}
                onClickMax={() => {
                  if (secondToken?.balance) {
                    const maxAvailableAmount = secondToken.isNative
                      ? secondToken.balance.sub(BigNumber.from(DUST_BNB).mul(2))
                      : secondToken.balance

                    const formattedMaxAvailableAmount = formatAmountFree(
                      maxAvailableAmount,
                      secondToken.decimals,
                    )
                    const finalAmount = isMetamaskMobile
                      ? limitDecimals(
                          formattedMaxAvailableAmount,
                          MAX_METAMASK_MOBILE_DECIMALS,
                        )
                      : formattedMaxAvailableAmount
                    setSecondTokenInputValue(finalAmount)
                    onFocusedCollateralInputChange(secondToken.address)
                  }
                }}
              >
                <div className="flex items-center gap-2">
                  <RenderTokenIcon
                    symbol={secondToken?.symbol}
                    size="small"
                    state={secondTokenInputValue ? 'active' : 'default'}
                  />
                  <span>{secondToken?.symbol}</span>
                </div>
              </BuyInputSection>
            )
          }
          longToken={longTokenInputState?.token}
          shortToken={shortTokenInputState?.token}
          marketTokenAmount={amounts?.marketTokenAmount!}
          marketTokenUsd={amounts?.marketTokenUsd!}
          longTokenAmount={amounts?.longTokenAmount}
          longTokenUsd={amounts?.longTokenUsd}
          shortTokenAmount={amounts?.shortTokenAmount}
          shortTokenUsd={amounts?.shortTokenUsd}
          fees={fees!}
          error={submitState.error}
          isDeposit={isDeposit}
          executionFee={executionFee}
          setPendingTxns={p.setPendingTxns}
          onSubmitted={() => {
            setStage('swap')
          }}
          onClose={() => {
            setStage('swap')
          }}
          isHighPriceImpact={isHighPriceImpact!}
          isHighPriceImpactAccepted={isHighPriceImpactAccepted}
          setIsHighPriceImpactAccepted={setIsHighPriceImpactAccepted}
          shouldDisableValidation={shouldDisableValidation}
        />
      )}
    </div>
  )
}
