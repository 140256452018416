import Tooltip from '@components/shared/Tooltip'
import cx from 'classnames'
import ExchangeInfoRow from 'components/Exchange/ExchangeInfoRow'
import StatsTooltipRow from 'components/StatsTooltip/StatsTooltipRow'
import { ExecutionFee, FeeItem } from 'domain/synthetics/fees'
import { formatDeltaUsd, formatTokenAmountWithUsd } from 'rfx/lib/numbers'

type Props = {
  totalFees?: FeeItem
  swapFee?: FeeItem
  swapPriceImpact?: FeeItem
  executionFee?: ExecutionFee
  isDeposit: boolean
}

export function GmFees(p: Props) {
  const totalFeesUsd = p.totalFees?.deltaUsd.sub(p.executionFee?.feeUsd || 0)

  return (
    <ExchangeInfoRow
      label={<>Fees and Price Impact</>}
      value={
        <>
          {!p.totalFees?.deltaUsd && '-'}
          {p.totalFees?.deltaUsd && (
            <Tooltip
              className="GmFees-tooltip"
              content={
                <div>
                  {p.swapPriceImpact?.deltaUsd.abs().gt(0) && (
                    <StatsTooltipRow
                      label={`Price Impact`}
                      value={
                        formatDeltaUsd(
                          p.swapPriceImpact.deltaUsd,
                          p.swapPriceImpact.bps,
                        )!
                      }
                      showDollar={false}
                      className={
                        p.swapPriceImpact.deltaUsd.gte(0)
                          ? 'text-th-success'
                          : 'text-th-error'
                      }
                    />
                  )}

                  {p.swapFee && (
                    <>
                      <StatsTooltipRow
                        label={p.isDeposit ? `Buy Fee` : `Sell Fee`}
                        value={
                          formatDeltaUsd(p.swapFee.deltaUsd, p.swapFee.bps, {
                            toFixed: 4,
                          })!
                        }
                        showDollar={false}
                        className={
                          p.swapFee.deltaUsd.gte(0)
                            ? 'text-th-success'
                            : 'text-th-error'
                        }
                      />
                    </>
                  )}

                  {p.executionFee && (
                    <StatsTooltipRow
                      label={`Max Execution Fee`}
                      value={formatTokenAmountWithUsd(
                        p.executionFee.feeTokenAmount.mul(-1),
                        p.executionFee.feeUsd.mul(-1),
                        p.executionFee.feeToken.symbol,
                        p.executionFee.feeToken.decimals,
                      )}
                      showDollar={false}
                    />
                  )}
                </div>
              }
            >
              {
                <span
                  className={cx({
                    positive: totalFeesUsd?.gt(0),
                    'text-th-success': totalFeesUsd?.gt(0),
                    'text-th-error': totalFeesUsd?.lt(0),
                  })}
                >
                  {formatDeltaUsd(totalFeesUsd, undefined, {
                    toFixed: 4,
                  })}
                </span>
              }
            </Tooltip>
          )}
        </>
      }
    />
  )
}
