import Image from 'next/image'
import React from 'react'
import { cn } from 'utils/classnames'
import { Chain } from 'viem'
import { arbitrum, base, mainnet, optimism, polygon, zksync } from 'viem/chains'

type Props = {
  chain: Chain
  usdcBalance?: number
  hideSymbol?: boolean
  nameClassName?: string
}

const chainLogos: Record<number, React.ReactNode> = {
  [base.id]: (
    <Image src="/logos/chains/base.svg" width={24} height={24} alt="Base" />
  ),
  [arbitrum.id]: (
    <Image src="/logos/chains/arb.svg" width={24} height={24} alt="ARB" />
  ),
  [optimism.id]: (
    <Image src="/logos/chains/op.svg" width={24} height={24} alt="OP" />
  ),
  [polygon.id]: (
    <Image
      src="/logos/chains/polygon.svg"
      width={24}
      height={24}
      alt="Polygon"
    />
  ),
  [mainnet.id]: (
    <Image src="/logos/chains/eth.svg" width={24} height={24} alt="Ethereum" />
  ),
  [zksync.id]: (
    <Image src="/icons/zksync.svg" width={24} height={24} alt="zkSync" />
  ),
}

const chainSymbols: Record<number, string> = {
  [zksync.id]: 'ZK',
  [arbitrum.id]: 'ARB',
  [optimism.id]: 'OP',
  [polygon.id]: 'POS',
  [mainnet.id]: 'ETH',
  [base.id]: 'BASE',
}

function DropdownContent(props: Props) {
  const { chain, hideSymbol, nameClassName } = props
  const logo = chainLogos[chain.id] || <></>
  const symbol = chainSymbols[chain.id] || ''

  return (
    <div className={cn('flex w-full flex-row items-center justify-between')}>
      <div className={cn('flex flex-row items-center gap-3')}>
        <div className={cn('flex h-6 w-6 items-center justify-center')}>
          {logo}
        </div>

        <div
          className={cn(
            'flex flex-col items-start justify-between tracking-normal',
          )}
        >
          <span
            className={cn('text-sm font-normal text-th-fgd-2', nameClassName)}
          >
            {chain.name}
          </span>
          {!hideSymbol && (
            <span className={cn('text-th-fgd-1/70 text-sm font-semibold')}>
              {symbol}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default DropdownContent
